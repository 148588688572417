
  .emoji {
    line-height: 1em;
    font-size: inherit;
  }

  .formRow {
    display: flex;

    > * {
      margin-top: 16px !important;

      + * {
        margin-left: 16px;
      }
    }
  }
