
  @import '../variables.scss';

  .container {
    position: relative;
  }

  .triggerBtn {
    appearance: none;
    font-size: 1.4em;
    width: $unit * 4;
    height: $unit * 4;
    line-height: $unit * 4;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    display: flex;
    justify-content: center;

    &:hover {
      background: var(--color-border);
    }
  }
