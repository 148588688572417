
  @import '../variables.scss';

  .itemWrapper {
    flex: 1;
  }

  .emojisWrapper {
    max-width: 33%;
    text-align: right;

    button {
      color: var(--color-text);
    }
  }

  .emojiDisplay {
    background: none;
    border: none;
    cursor: pointer;
    border-radius: $unit-half;
    font-size: inherit;
    display: inline-flex;
    align-items: center;

    &:hover,
    &:focus {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .emojiCount {
    font-size: 0.7em;
    line-height: 1em;
    margin-left: 4px;
  }

  .dragIcon {
    width: $unit-4;
    height: $unit-4;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      opacity: 0.6;
    }
  }
