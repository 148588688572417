
  @import '../variables.scss';

  .header {
    background: var(--color-primary);
    color: var(--color-tertiary);
    border-top-left-radius: $unit-half;
    border-top-right-radius: $unit-half;
    padding: $unit-2;
  }

  .heading {
    margin: 0;
    font-size: inherit;
  }
