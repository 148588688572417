
  @import '../variables.scss';

  .person {
    background: var(--color-theme);
    border: 1px solid var(--color-border);
    display: inline-flex;
    color: inherit;
    transition: background 0.1s ease-in-out;
    border-radius: $unit-half;
    font-size: inherit;
    align-items: center;
    margin: $unit;
    user-select: none;

    &[draggable='true'] {
      cursor: move;
    }
  }

  .photo {
    height: 3em;

    svg {
      stroke: none;
    }
  }

  .img {
    width: 3em;
    height: 3em;
    border-radius: $unit-half;
  }

  .name {
    padding: 0 $unit;
  }

  .buttons {
    margin-right: $unit-half;
  }
