
  @import '../variables.scss';

  .backdrop {
    z-index: 999;
    background: rgba(var(--color-box-shadow), 0.4);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dialog {
    background: var(--color-theme);
    border-radius: $unit-half;
    box-shadow: 0 0 $unit-2 0px rgba(0, 0, 0, 0.2);
    min-width: 400px;
    max-width: 90%;
  }
