
  .header {
    color: white;
    background: var(--color-primary);
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    width: 24px;
    flex: 0;
  }

  .spacerLeft {
    flex: 1;
  }

  .spacerRight {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
