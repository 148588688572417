
  @import '../variables.scss';

  .theBench {
    min-height: 100px;

    &.isDraggingOver {
      background: var(--color-border);
    }
  }
