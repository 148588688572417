
  @import '../variables.scss';

  .team {
    display: flex;
    height: calc(100% - 50px);

    @media screen and (max-width: $breakpoint) {
      height: initial;
      flex-direction: column-reverse;
    }
  }
