
  @import '../variables.scss';

  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $unit-2;
    font-size: 0.8em;
    width: 100%;
  }
