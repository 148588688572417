
  @import '../variables.scss';

  .lane {
    padding: $unit;
    display: flex;
    font-size: inherit;
    border-bottom: 1px solid var(--color-border);
    width: 100%;
    width: stretch;
    min-width: $unit * 30;
    align-items: center;

    &.isDraggingOver {
      background: var(--color-border);
    }
  }

  .entities {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
  }

  .people {
    flex-grow: 1;
  }

  .chips {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  .buttons {
    display: flex;
    flex-direction: column;
  }

  .broomIconButton svg {
    color: var(--color-text);
    stroke: none;
    width: 1.3em;
    height: 1.3em;
  }
