
  @import '../variables.scss';

  .createLane {
    min-height: 100px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &.isDraggingOver {
      background: var(--color-border);
    }
  }
