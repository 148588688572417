
  @import '../variables.scss';

  .input {
    color: var(--color-text);
    padding: $unit;
    display: block;
    font-size: inherit;
    font-weight: inherit;
    border: none;
    border-radius: $unit-half;
    width: 100%;
    width: stretch;
    min-width: $unit * 30;
    background: transparent;
  }

  .rendered {
    padding: $unit;

    p {
      margin: 0;
    }
  }

  .strikethrough {
    text-decoration: line-through;
  }
