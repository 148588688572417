
  @import '../variables.scss';

  .welcomeContainer {
    margin: auto;
    max-width: $unit * 76;
  }

  .formContainer,
  .formHeader {
    display: flex;
    justify-content: center;
  }

  .welcomeForm {
    padding: $unit * 3;
    max-width: $unit * 40;
    display: flex;
    flex-direction: column;

    + .welcomeForm {
      border-left: 1px solid var(--color-border);
    }
  }

  .verticalFiller {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .error {
    border-radius: $unit-half;
    background: --color-danger;
    opacity: 0.9;
    padding: $unit;
    color: var(--color-danger);
    margin-top: $unit-3;
  }

  .buttons {
    margin-top: $unit-3;
    display: flex;
  }
