
  @import '../variables.scss';

  .item {
    list-style-type: none;
    min-width: 100%;
    display: flex;
    align-items: center;
  }

  .button {
    color: var(--color-text);
    display: inline-block;
    text-decoration: none;
    background: none;
    border: none;
    font-size: inherit;
    font-family: inherit;
    padding: $unit;
    cursor: pointer;
    min-width: 100%;
    text-align: left;

    &:hover,
    &:focus {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .leftIcon {
    margin-right: $unit;
  }
