
  @import '../variables.scss';

  .group {
    padding: 0;
    margin: 0;
  }

  .name {
    font-weight: bold;
    color: var(--color-text);
    padding: $unit;
  }
