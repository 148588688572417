
  .headerButton {
    background: none;
    border: none;
    padding: 8px;
    margin: 8px;
    display: flex;
    color: inherit;
    transition: background 0.1s ease-in-out;
    border-radius: 4px;
    font-size: inherit;
    align-items: center;
    cursor: pointer;
    position: relative;

    &:disabled {
      pointer-events: none;
    }

    &:hover,
    &:focus {
      background: rgba(var(--color-theme), 0.1);
    }

    &.bold {
      font-weight: bold;
    }

    svg {
      stroke: var(--color-tertiary);
    }
  }

  svg {
    height: 0.8em;
    width: 0.8em;
    vertical-align: -0.1em;
  }

  .image {
    width: 1em;
    height: 1em;
    margin-right: 8px;
    border-radius: 2px;
    background-color: var(--color-theme);
  }

  .icon {
    margin-left: 4px;
  }
