
  @import '../variables.scss';

  .select {
    padding: 0 $unit;
    display: block;
    font-size: 1em;
    border: none;
    border-radius: $unit-half;
    width: 100%;
    width: stretch;
    min-width: $unit * 30;

    svg {
      display: none;
    }
  }
