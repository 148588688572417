
  @import '../variables.scss';

  .chooseTeam {
    width: 320px;
    margin: 30vh auto;
    text-align: center;
  }

  .teamList {
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      margin-bottom: $unit-2;
      list-style-type: none;
    }
  }

  .small {
    font-size: 0.8em;
  }
