
  @import '../variables.scss';

  .main {
    padding: $unit-2;
    display: flex;
    flex-direction: column;

    &.row {
      flex-direction: row;
    }
  }
