
  @import '../variables.scss';

  .container {
    position: relative;
    z-index: 999;
  }

  .menu {
    position: absolute;
    z-index: 999;
    top: calc(100% - 2px);
    background: var(--color-theme);
    border-radius: $unit-half;
    box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0;
    margin: 0;
    width: max-content;
  }

  .left {
    left: $unit;
  }

  .right {
    right: $unit;
  }
