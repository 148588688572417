
  @import '../variables.scss';

  .chip {
    display: inline-flex;
    align-items: center;
    position: relative;
    user-select: none;
    margin: $unit-half;
    overflow-wrap: anywhere;

    &[draggable='true'] {
      cursor: move;
    }

    &:hover {
      .editButton {
        display: initial;
      }
    }
  }

  .editButton {
    display: none;
    position: absolute;
    border-radius: 9999px;
    background: rgba(255, 255, 255, 0.6);
    right: 2px;
    top: 50%;
    transform: translateY(-50%);

    &:hover,
    &:focus {
      background: rgba(255, 255, 255, 0.9);
    }

    svg {
      stroke: var(--color-theme);
    }
  }

  .track {
    padding: $unit;
    border-radius: 0;
  }

  .role {
    padding: $unit ($unit * 1.5);
    border-radius: 9999px;
  }

  .emoji {
    height: inherit;
    line-height: 1em;
    font-size: 1.4em;
    font-family: initial;
    margin-right: $unit;
  }
