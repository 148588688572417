
  @import '../variables.scss';

  .alert {
    z-index: 9999;
    box-shadow: 0 0 $unit-2 0px rgba(var(--color-box-shadow), 0.2);
    background: var(--color-danger);
    color: var(--color-theme);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: $unit-3;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 440px;
    padding: $unit-2;
    border-radius: $unit-half;
  }
