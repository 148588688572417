
  @import '../variables.scss';

  .heading {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: inherit;
  }

  .imageContainer {
    width: 192px;
    height: 192px;
    margin-right: 16px;
  }

  .image {
    width: 192px;
    height: 192px;
    stroke: none;
  }

  .advancedOptionsSection {
    display: flex;
    margin: $unit 0 $unit-2 0;
  }

  .advancedOptionsHeader {
    margin-left: $unit-half;
    padding-top: $unit;
  }

  .optionsIcon {
    transition: 0.9s;
    transform: rotate(90deg);
    fill: var(--color-border);
  }

  .optionsIconClicked {
    transform: rotate(180deg);
  }
