
  @import '../variables.scss';

  .select {
    color: var(--color-text);
    padding: $unit;
    display: block;
    font-size: 1em;
    border: none;
    border-radius: $unit-half;
    width: 100%;
    width: stretch;
    min-width: $unit * 30;
    appearance: none;
    background: rgba(var(--color-box-shadow), 0.05);
  }
