
  @import '../variables.scss';

  .label {
    font-weight: bold;
    display: block;
    margin-bottom: $unit-half;
  }

  .textarea {
    padding: $unit;
    display: block;
    font-size: inherit;
    font-family: inherit;
    border: none;
    border-radius: $unit-half;
    width: 100%;
    width: stretch;
    min-width: $unit * 30;
    background: rgba(0, 0, 0, 0.05);
    resize: none;
  }

  .container + .container {
    margin-top: $unit-2;
  }
