
  @import '../variables.scss';

  .grow {
    flex-grow: 1;
  }

  .label {
    font-weight: bold;
    display: block;
    margin-bottom: $unit-half;
  }

  .container:not(:first-child) {
    margin-top: $unit-2;
  }
